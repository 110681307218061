import { API_URL } from "../../config.js";

const consolidadoService = (() => {
  const axios = require("axios");

  const instance = axios.create({
    baseURL: API_URL,
    withCredentials: true, // Include cookies in requests
  });

  instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("jwt-token");
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const addConsolidado = async (Consolidado) => {
    try {
      const response = await instance.post("/ConsolidacionStock", Consolidado);
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const getAllConsolidados = async () => {
    try {
      const response = await instance.get("/ConsolidacionStock");
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const getById = async (id) => {
    try {
      const response = await instance.get(`/ConsolidacionStock/${id}`);
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const editConsolidado = async (id, Consolidado) => {
    try {
      const response = await instance.put(`/ConsolidacionStock/${id}`, Consolidado);
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const deleteConsolidado = async (id) => {
    try {
      const response = await instance.delete(`/ConsolidacionStock/${id}`);
      if (response.status === 204) {
        return response.data;
      } else {
        throw new Error("Unexpected server response.");
      }
    } catch (error) {
      if (error.response) {
        console.log("Error deleting Consolidado:", error.response.data);

        throw error.response.data;
      } else {
        console.log("Non-response related error:", error);
        throw new Error("Error deleting Consolidado.");
      }
    }
  };

  return {
    addConsolidado,
    getById,
    editConsolidado,
    deleteConsolidado,
    getAllConsolidados,
  };
})();

export default consolidadoService;
