import { API_URL } from "../../config.js";

const insumoService = (() => {
  const axios = require("axios");

  const instance = axios.create({
    baseURL: API_URL,
    withCredentials: true, // Include cookies in requests
  });

  instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("jwt-token");
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const addProducto = async (Producto) => {
    try {
      const response = await instance.post("/Producto", Producto);
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const getAllProductos = async () => {
    try {
      const response = await instance.get("/Producto");
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const getById = async (id) => {
    try {
      const response = await instance.get(`/Producto/${id}`);
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const editProducto = async (id, Producto) => {
    try {
      const response = await instance.put(`/Producto/${id}`, Producto);
      // Como el servidor puede devolver 204 No Content, no se espera respuesta
      if (response.status === 204) {
        return { success: true }; // Indica que la actualización fue exitosa
      } else {
        return response.data; // En caso de que haya algún contenido
      }
    } catch (error) {
      if (error.response) {
        console.log("Error response data:", error.response.data); // Imprime el cuerpo del error
        return { success: false, error: error.response.data }; // Manejo de error
      } else {
        console.log("Error:", error);
        return { success: false, error: "Error en la solicitud." }; // Manejo de error
      }
    }
  };

  const deleteProducto = async (id) => {
    try {
      const response = await instance.delete(`/Producto/${id}`);
      if (response.status === 204) {
        return response.data;
      } else {
        throw new Error("Unexpected server response.");
      }
    } catch (error) {
      if (error.response) {
        console.log("Error deleting Producto:", error.response.data);

        throw error.response.data;
      } else {
        console.log("Non-response related error:", error);
        throw new Error("Error deleting Producto.");
      }
    }
  };

  return {
    addProducto,
    getById,
    editProducto,
    deleteProducto,
    getAllProductos,
  };
})();

export default insumoService;
