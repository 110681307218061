<template>
    <div class="grid grid-flow-row gap-4">
        <div class="flex justify-center min-height-auto">
            <h1
                class="text-3xl text-center font-bold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-3xl">
                Presupuestos
            </h1>
        </div>
        <span>
            <svg class="w-5 h-5 inline-block text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                    d="M18.796 4H5.204a1 1 0 0 0-.753 1.659l5.302 6.058a1 1 0 0 1 .247.659v4.874a.5.5 0 0 0 .2.4l3 2.25a.5.5 0 0 0 .8-.4v-7.124a1 1 0 0 1 .247-.659l5.302-6.059c.566-.646.106-1.658-.753-1.658Z" />
            </svg>
            Filtro por fecha:
        </span>
        <div class="grid grid-cols-2 lg:grid-cols-5 gap-3">
            <div>
                <input type="date" v-model="fechaInicio"
                    class="w-full form-control block px-3 py-2 border border-gray-300 rounded-md text-gray-700 bg-white appearance-none cursor-pointer focus:outline-none focus:ring-1 focus:ring-blue-500" />
            </div>
            <div>
                <input type="date" v-model="fechaFin"
                    class="w-full form-control block px-3 py-2 border border-gray-300 rounded-md text-gray-700 bg-white appearance-none cursor-pointer focus:outline-none focus:ring-1 focus:ring-blue-500" />
            </div>
            <div>
                <button type="button" @click="filtrarPorFecha"
                    class="w-full text-white bg-[#3edfa9] hover:bg-[#ffe068] font-medium rounded-lg text-sm px-2 md:px-5 py-2.5">
                    <svg class="w-6 h-6 inline-block text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                        width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4" />
                    </svg>
                    Filtrar
                </button>
            </div>
            <div v-if="filtrosActivos">
                <button type="button" @click="resetearFiltros"
                    class="w-full text-white bg-red-500 hover:bg-red-600 font-medium rounded-lg text-sm px-2 md:px-5 py-2.5">
                    <svg class="w-6 h-6 inline-block text-white" viewBox="0 0 32 32" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" fill="none">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                            <title>cross-circle</title>
                            <desc>Created with Sketch Beta.</desc>
                            <defs></defs>
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                                sketch:type="MSPage">
                                <g id="Icon-Set" sketch:type="MSLayerGroup"
                                    transform="translate(-568.000000, -1087.000000)" fill="#fff">
                                    <path
                                        d="M584,1117 C576.268,1117 570,1110.73 570,1103 C570,1095.27 576.268,1089 584,1089 C591.732,1089 598,1095.27 598,1103 C598,1110.73 591.732,1117 584,1117 L584,1117 Z M584,1087 C575.163,1087 568,1094.16 568,1103 C568,1111.84 575.163,1119 584,1119 C592.837,1119 600,1111.84 600,1103 C600,1094.16 592.837,1087 584,1087 L584,1087 Z M589.717,1097.28 C589.323,1096.89 588.686,1096.89 588.292,1097.28 L583.994,1101.58 L579.758,1097.34 C579.367,1096.95 578.733,1096.95 578.344,1097.34 C577.953,1097.73 577.953,1098.37 578.344,1098.76 L582.58,1102.99 L578.314,1107.26 C577.921,1107.65 577.921,1108.29 578.314,1108.69 C578.708,1109.08 579.346,1109.08 579.74,1108.69 L584.006,1104.42 L588.242,1108.66 C588.633,1109.05 589.267,1109.05 589.657,1108.66 C590.048,1108.27 590.048,1107.63 589.657,1107.24 L585.42,1103.01 L589.717,1098.71 C590.11,1098.31 590.11,1097.68 589.717,1097.28 L589.717,1097.28 Z"
                                        id="cross-circle" sketch:type="MSShapeGroup"></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                    Resetear Filtros
                </button>
            </div>
            <div>
                <router-link to="/formpresupuesto">
                    <button type="button"
                        class="w-full focus:outline-none text-white bg-[#3edfa9] hover:bg-[#ffe068] focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-2 md:px-5 py-2.5">
                        <svg class="w-6 h-6 inline-block text-white" aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M12 7.757v8.486M7.757 12h8.486M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        Agregar
                    </button></router-link>
            </div>
            <div>
                <form>
                    <select name="results" id="results"
                        class="bg-gray-50 border border-[#3edfa9] text-gray-900 text-sm rounded-lg focus:ring-[#3edfa9] focus:border-[#3edfa9] block w-full p-2.5">
                        <option selected value="10">Mostrar resultados</option>
                        <option value="20">Mostrando 20 resultados</option>
                        <option value="30">Mostrando 30 resultados</option>
                        <option value="50">Mostrando 50 resultados</option>
                    </select>
                </form>
            </div>
        </div>
        <hr class="divide-y divide-gray-100" />
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                <thead class="text-xs text-gray-700 uppercase bg-[#96ffdf]">
                    <tr>
                        <th scope="col" class="px-4 py-3">Fecha</th>
                        <th scope="col" class="px-4 py-3">Tipo servicio</th>
                        <th scope="col" class="px-4 py-3">Descripcion</th>
                        <th scope="col" class="px-4 py-3">Estatus</th>
                        <th scope="col" class="px-4 py-3">Total </th>

                        <th scope="col" class="px-4 py-3">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="bg-white border-b">
                        <th scope="row" class="px-4 py-4 font-medium text-gray-900 whitespace-nowrap">
                            17/01/2024
                        </th>
                        <td class="px-4 py-4">
                            Desarrollo web</td>


                        <td class="px-4 py-4">
                            <ul>
                                <li>Domino .com</li>
                                <li>Hosting 5GB</li>
                                <li>3 Cuentas de Correo</li>
                            </ul>
                        </td>
                        <td class="px-4 py-4"><span
                                class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">Ativo</span>
                        </td>
                        <td class="px-4 py-4">
                            $1500
                        </td>
                        <td class="grid lg:flex md:flex px-2 md:px-2 py-4 lg:text-left md:text-center">
                            <router-link to="/formpresupuesto" class="font-regular text-xs text-gray-800 hover:underline">
                                <svg class="w-4 h-4 inline-block text-gray-800 dark:text-white" aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                    viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2"
                                        d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z" />
                                </svg>

                                Editar</router-link>
                            <a href="#" @click="deleteModal(consolidado.id)"
                                class="font-medium text-xs text-red-500 hover:underline lg:ml-2"><svg
                                    class="w-4 h-4 inline-block text-gray-800" aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <path stroke="#ef4444" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
                                </svg>
                                Eliminar</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <Modal message="Registro" @confirm="eliminarConsolidado" ref="modalComponent" />
</template>

<script>
  import consolidadoService from "../../composables/api/consolidadoService";
  import insumoService from "../../composables/api/insumoService";
  import { ref, onMounted } from "vue";
  import Modal from "../modalConfirmar/ModalConfirmar.vue";
  
  export default {
    components: {
      Modal,
    },
    name: "consolidados",
    setup() {
      const consolidados = ref([]);
      const productos = ref({});
      const fechaInicio = ref("");
      const fechaFin = ref("");
      const filtrosActivos = ref(false);
      const modalComponent = ref(null);
      const modal = ref();
  
      const fetchConsolidados = async () => {
        try {
          consolidados.value = await consolidadoService.getAllConsolidados();
          await fetchProductos();
        } catch (error) {
          console.error(error);
        }
      };
  
      //Carga la lista de articulos para asociar el Id con el nombre del articulo
      const fetchProductos = async () => {
        try {
          const productosData = await insumoService.getAllProductos();
          productosData.forEach((producto) => {
            productos.value[producto.id] = {
              nombre: producto.nombre,
              descripcion: producto.descripcion,
            };
          });
        } catch (error) {
          console.error("Error al obtener los productos:", error);
        }
      };
  
      //Filtro por fechas
      const filtrarPorFecha = () => {
        if (fechaInicio.value && fechaFin.value) {
          const inicio = new Date(fechaInicio.value);
          const fin = new Date(fechaFin.value);
  
          // Convertir las fechas a formato YYYY-MM-DD para compararlas sin hora
          const inicioStr = inicio.toISOString().split("T")[0];
          const finStr = fin.toISOString().split("T")[0];
  
          consolidados.value = consolidados.value.filter((consolidado) => {
            const fechaRegistro = new Date(consolidado.fechaRegistro);
            const fechaRegistroStr = fechaRegistro.toISOString().split("T")[0];
  
            return fechaRegistroStr >= inicioStr && fechaRegistroStr <= finStr;
          });
  
          filtrosActivos.value = true;
        } else {
          // Si no hay fechas, puedes optar por mostrar todos los consolidados
          fetchConsolidados();
        }
      };
  
      const resetearFiltros = () => {
        // Restablecer los valores de las fechas
        fechaInicio.value = "";
        fechaFin.value = "";
  
        // Restablecer los consolidados a la lista completa
        fetchConsolidados();
  
        // Actualizar el estado de filtros activos
        filtrosActivos.value = false;
      };
  
      const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      };
  
      const eliminarConsolidado = async (id) => {
        try {
          await consolidadoService.deleteConsolidado(id);
          consolidados.value = consolidados.value.filter(
            (consolidado) => consolidado.id !== id
          );
        } catch (error) {
          console.error("Error al eliminar el insumo:", error);
        }
      };
  
      const deleteModal = async (consolidado) => {
        modal.value = await modalComponent.value.getModal(consolidado);
        //modal.value.show();
      };
  
      onMounted(() => {
        fetchConsolidados();
      });
  
      return {
        consolidados,
        eliminarConsolidado,
        formatDate,
        productos,
        fechaInicio,
        fechaFin,
        filtrarPorFecha,
        filtrosActivos,
        resetearFiltros,
        modalComponent,
        modal,
        deleteModal
      };
    },
  };
  </script>